

.keywords-modal-container{
  min-height: 388px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 36px;
}

.KeywordsModalContent{
  text-align: left;
  border-radius: 16px;
  max-width: 855px;
  padding: 28px 28px 28px 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.KeywordsModalHeader{
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #1F1F22;
  margin: 0px;
}
.keywords-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: max(592px, 60vh);
  overflow-y: auto;
  gap: 8px
}
.keywords-container::-webkit-scrollbar {
  width: 6px;
  background: rgba(243, 243, 248, 0.5);
}
.keywords-container::-webkit-scrollbar-thumb {
  background: #e7e8ee;
  border-radius: 6px;
}
@media screen and (max-width: 1000px) {
  .KeywordsModalContent{
    border-radius: 16px 16px 0 0;
  }
  .keywords-container{
    max-height: max(492px, 60vh);
  }
}

.modal-stick-wrapper {
  display: none;
}

@media screen and (max-width: 1300px) {
  .modal-stick-wrapper {
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  }

  .swap-modal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition-duration: .7s;
    position: fixed;
    padding: 16px 16px 34px 16px;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgb(241, 242, 248) !important;
    box-shadow: 0px -10px 7px rgba(100, 53, 165, 0.1), 0px 0px 0px rgba(100, 53, 165, 0.11);
    border-radius: 12px 12px 0 0;
    z-index: 10;
  }
}

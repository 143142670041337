.ContainerLanding {
  display: flex;
  justify-content: center;

}

.Landing {
  max-width: 1920px;
  min-height: 100vh;
  margin-top: 24px;

  .LandingCardFlex {
    display: flex;
    justify-content: space-around;

    .InfoCard {
      border-radius: 20px;
      background: white;
      padding: 2.5rem;
      margin-bottom: 20px;
    }

    .InfoTitle {
      max-width: 700px;
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 40px;
    }

    .HelloCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 70px 30px;
      justify-content: center;
      border-radius: 20px;
      background: var(--color-5-dark);
      width: 540px;
      margin-bottom: 20px;

      .HelloText {
        margin-top: 50px;
        font-weight: 500;
        font-size: 24px;
        color: white;
      }
    }

    .point-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 24px;

      .InfoText {
        margin-left:20px;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #1F1F22;
      }

      .letter-icon {
        margin-right: 10px;
      }

    }

    &.FirstBlock {
      max-width: 1500px;


      .Desires {
        flex-grow: 4;

        .ImgMain {
          margin-bottom: 30px;
        }

        .MainTitle {
          font-weight: 800;
          font-size: 64px;
          margin-bottom: 56px;
          max-width: 600px;
        }

        .FlexButton {
          margin-bottom: 40px;
          display: flex;
          gap: 24px;

          .SecondaryButton {
            color: var(--color-5-dark);
            border-radius: 8px;
            padding: 20px 34px;
            background: white;
            border: 1px solid var(--color-5-dark);
            transition: 0.3s;
            font-weight: 500;
            font-size: 20px;

            &:hover {
              background: #F3F3FE;
              color: #AE78FE;
            }

            &:active {
              background: #F3F3FE;
              color: #7328E2;
            }
          }

          .MainButton {
            transition: 0.3s;
            margin-right: inherit;
            align-self: center;
            background: var(--color-5-dark);
            color: white;
            font-weight: 500;
            font-size: 20px;
            border: 0;
            border-radius: 8px;
            padding: 20px 44px;

            &:hover {
              background: #AE78FE;
              color: white;
            }

            &:active {
              background: #7328E2;
              color: white;
            }
          }
        }
      }
    }
  }

  .LandingCard {
    background: white;
    border-radius: 1.25rem;
    padding: 70px 51px 55px 4rem;
    margin-bottom: 20px;

    .HeaderSubtext {
      display: flex;
      flex-wrap: wrap;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: var(--text-secondary);
    }
  }

  .FinalCard {
    align-items: center;
    border-radius: 20px;
    background: white;
    padding: 2rem;

    .FinalCardTitle {
      font-weight: 500;
      font-size: 22px;
    }
  }

  .Stars {
    margin-right: 23px;
  }

  .StarsMobil {
    display: none !important;
  }
}


@media screen and (max-width: 988px) {
  .Landing {
    .LandingCardFlex {
      display: inherit;

      .InfoTitle {
        margin-bottom: 24px;
      }

      .HelloCard {
        padding: 20px;
        width: 100%;
        margin-right: inherit !important;
        margin-bottom: 20px;

      }


      &.FirstBlock {
        .Desires {
          .ImgMain {
            align-content: center;
            align-items: center;

            .ImgMain  {
              width: 184px;
              height: 194px;
            }
          }

          .MainTitle {
            margin-bottom: 45px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            max-width: 100%;
          }

          .FlexButton {
            margin-bottom: 40px;
            display: flex;
            justify-content: center;
            gap: 10px;

            .LinkProfession {
              height: fit-content;
              text-align: center;
            }

            .SecondaryButton {
              font-size: 17px;
              padding: 12px 10px;
            }

            .MainButton {
              width: 104px;
              font-size: 17px;
              padding: 12px 10px;

            }
          }
        }
      }
    }

    .LandingCard {
      padding: 20px 14px;

      .HeaderSubtext {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        text-align: center;
        justify-content: center;
      }
    }

    .Stars {
      display: none !important;
    }

    .StarsMobil {
      display: inherit !important;

      .startHumanIcon {
        width: 184px;
        height: 194px;
      }
    }
  }

  .FinalCard {
    .FlexEducationalTrajectory {
      display: flex;
    }

    .mobilStar {
      display: flex;
    }
  }

}

@media screen and (max-width: 724px) {
  .Landing {

    .LandingCardFlex {

      .InfoTitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 24px;
      }

      .HelloCard {
        width: 100%;

        .HelloText {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          margin-top: 35px;
        }
      }

      .point-wrapper {

        .InfoText {


          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #1F1F22;
        }
      }

      &.FirstBlock {
        .Desires {
          .MainTitle {
            font-size: 24px;
            margin-bottom: 42px;
          }

          .FlexButton {
            .SecondaryButton {
              width: 104px;
              font-size: 14px;
            }

            .MainButton {
              width: 104px;
              font-size: 14px;

            }
          }
        }
      }
    }

    .FinalCard {
      .FinalCardTitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
    }

  }
}


@media screen and (max-width: 375px) {
  .point-wrapper {
    .InfoText {
      font-size: 16px;
    }
  }
  .FlexButton {
    .SecondaryButton {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 331px) {
  .FlexButton {
    .MainButton {
      width: 104px;
    }
  }
}

.marginText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.RandomFeedback.NotVisible {
  transform: scale(0);
  transition-duration: 0.3s;
  /*transition: opacity 0.3s, transform 0s 0.3s ;*/
}

.RandomFeedback {
  box-shadow: 0px -1px 24px rgba(31, 31, 34, 0.02), 1px 4px 24px rgba(31, 31, 34, 0.06);
  position: absolute;
  bottom: 40px;

  transform: scale(1);
  /*transition: opacity 0.3s, transform 0s 0.3s ;*/
  transition-duration: 0.3s;

  right: 50px;
  border-radius: 16px;
}

.MobilFix {
  display: block;
}

.MainButton {
  margin-right: 0.5rem
}

.tilesFlex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px
}

.tileHeader {
  margin-bottom: 12px;
  font-weight: 600;
}

.CourseButtonDiploma {
  border: 0;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 12px;
  border-radius: 8px 8px 0px 0px;
  color: var(--color-5-dark);
  background-color: white;
}

.DiplomaCard {
  border-radius: 16px;
  padding: 25px;
  border: 0;
  min-height: 381px;
}

.CourseButton {
  background: transparent;
  border: 0;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 12px;
  border-radius: 8px 8px 0px 0px;
}

.controlTypeCard {
  cursor: pointer;
  background: #78a3ec;
  border-radius: 20px;
  color: white;
  min-width: 180px;
  height: 104px;
  padding: 20px;
}

.classesTypeCard {
  cursor: pointer;
  border-radius: 20px;
  color: white;
  min-width: 180px;
  height: 134px;
  padding: 20px;
  background: #78a3ec;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.HeaderDivider {
  padding: 0;
  margin: 0;
  background: white;
  height: 2px;
  border: 0;
}

.mainButtonDiploma {
  font-size: 14px;
  padding: 10px 12px;
}

.secondary-button-diploma {
  font-size: 14px;
  padding: 10px 12px;
}

.textSmall {
  font-size: 12px;
  line-height: 16px;
}



.mobi-opacity-block {
  display: none;
}


// Made by Danil --start--

.diploma-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
}

.tiles-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 30px;

  .left-tiles {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 514px;
    min-width: 300px;
  }

  .right-tiles {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  .button-text {
    font-weight: 500;
    font-size: 14px;
  }

  .share-button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

.white-tile-wrapper {
  background: #fff;
  padding: 20px 24px;
  border-radius: 16px;
}

.disciplines-tile {
  min-height: 381px;
  width: max-content;
  max-width: 830px;

  .disciplines-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.control-types-tile {
  width: 830px;
  max-width: 830px;

  .control-types-wrapper {
    display: flex;
    gap: 20px;
  }
}

.modal-stick-wrapper {
  display: none;
}

// --end--

@media screen and (max-width: 1300px) {
  .diploma-container {
    padding-bottom: 64px;
  }

  .tiles-wrapper {
    max-width: 700px;
    width: 100%;
    flex-direction: column;

    .left-tiles {
      max-width: 700px;
      min-width: 0;
    }

    .right-tiles {
      max-width: 100%;
    }
  }

  .disciplines-tile {
    width: 100%;

    .disciplines-wrapper {
      width: 100%;
    }

    .mobile-card {
      border-radius: 8px;
    }
  }

  .control-types-tile {
    width: 100%;
    max-width: none;


    .control-types-wrapper {
      display: flex;
      overflow-x: scroll;

      .mobile-card {
        border-radius: 8px;
      }
    }
  }

  .buttons-wrapper {
    .mobile-button {
      width: 100%;
    }
  }

  .white-tile-wrapper {
    transition: height .3s;

    &.share-tile {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #F3F3F8;
      box-shadow: 0px -10px 7px rgba(100, 53, 165, 0.1), 0px 0px 0px rgba(100, 53, 165, 0.11);
      border-radius: 12px 12px 0 0;
    }
  }

  .modal-stick-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  }

  .card-animation {
    height: 100%;
    transition: height 0.3s ease-in;
  }
}

@media screen and (max-width: 750px) {
  .right-tiles {
    max-width: 100%;
  }

  .control-types-tile {
    max-width: calc(100vw - 30px);
    min-width: 300px;
    width: 100%;
  }


  .control-types-wrapper {
    .control-type-card-mobile {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 40px;
      height: 82px;
    }
  }
}

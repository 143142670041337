.selectedPresetsContainer {
  position: relative;
  max-height: 100%;
  .addPreset {
    display: flex;
    justify-content: center;
    .add-button {
      font-weight: 700;
      width: min-content;
      padding: 10px 12px;
      border-radius: 8px;

      &:hover {
        background: #F3F3F8;
      }
      &:active{
        color: #7328E2;
      }
    }
  }
  .blockMyPreset {
    gap: 32px;
    width: 100%;
    background: #F3F3F8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 20px;
    &.main {
      background: inherit;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6E6D79;
    }
  }
  .scrollBtn {
    transition: all 0.2s;
    padding: 0;
    position: absolute;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 9;
    right: -10px;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 26px 11px rgba(100, 53, 165, 0.01), 0px 15px 9px rgba(100, 53, 165, 0.03), 0px 7px 7px rgba(100, 53, 165, 0.05), 0px 0px 4px rgba(100, 53, 165, 0.06), 0px 0px 0px rgba(100, 53, 165, 0.06);
    &.left {
      left: -10px;
      //transform: scaleX(-1);
    }
  }
  .prompt {
    width: 257px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      margin-bottom: 27px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6E6D79;
    }
    button {
      padding-left: inherit;
      text-align: left;
      color: #8533FF;
      background: inherit;
      border: inherit;
    }
  }
  .leftSlide{
    display: flex;
    transition: all 0.3s;
    //height: 340px;
    border-bottom: 1px solid rgba(231, 232, 238, 0);
    gap: 24px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    padding-bottom: 24px;
    -webkit-overflow-scrolling: touch;


    &::-webkit-scrollbar{
      display: none;
    }

    &.hidden {
      max-height: 0;
      border-bottom: 1px solid #E7E8EE;
      .scrollBtn.right {
        right: 0;
      }
      .scrollBtn.left {
        height: 0;
        width: 0;
        top: calc(50%);
      }
    }
    &.hidden-right{
      .scrollBtn.right {
        height: 0;
        width: 0;
        top: calc(50%);
        right: 0;
      }
    }
    &.hidden-left{
      .scrollBtn.left {
        height: 0;
        width: 0;
        top: calc(50%);
        left: 0;
      }
    }

    .preset:first-child{
      margin-left: 4px;
    }
    .preset:last-child{
      margin-right: 4px;
    }
  }
}

.scrollBtn {
  overflow: hidden;
  transition: all 0.3s;
  padding: 0;
  position: absolute;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 9;
  right: -10px;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 26px 11px rgba(100, 53, 165, 0.01), 0px 15px 9px rgba(100, 53, 165, 0.03), 0px 7px 7px rgba(100, 53, 165, 0.05), 0px 0px 4px rgba(100, 53, 165, 0.06), 0px 0px 0px rgba(100, 53, 165, 0.06);
}

.scrollBtn.left {
  left: -10px;
  //transform: scaleX(-1);
}


.leftSlide .presets {
  /*min-width: 437px;*/
  margin-bottom: 16px;
}

.leftSlide {
  display: flex;
  transition: all 0.3s;
  //height: 320px;
  border-bottom: 1px solid rgba(231, 232, 238, 0);
  gap: 15px;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.leftSlide.hidden {
  max-height: 0;
  border-bottom: 1px solid #E7E8EE;
}


.leftSlide.hidden .scrollBtn.right {
  height: 0;
  width: 0;
  top: calc(50%);
  right: 0;
}

.leftSlide.hidden .scrollBtn.left {
  height: 0;
  width: 0;
  top: calc(50%);
}

.leftSlide.hidden-right .scrollBtn.right {
  height: 0;
  width: 0;
  top: calc(50%);
  right: 0;
}

.leftSlide.hidden-left .scrollBtn.left {
  height: 0;
  width: 0;
  top: calc(50%);
  left: 0;
}

.prompt {
  width: 257px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

@media screen and (max-width: 879px) {
  .selectedPresetsContainer .prompt button {
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .selectedPresetsContainer {
    .blockMyPreset.main {
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      height: unset;
    }
    .flexPreset {
      min-width: 100%;
    }
    .leftSlide.activ {
      display: flex;
      max-height: 300px;
      transition: all 0.5s;
      height: calc(100%);
    }

    .leftSlide.noActiv {
      max-height: 0;
      height: calc(0%);
      transition: all 0.5s;
      transition-duration: 0.3s;
    }
  }
}

.Header {
  //background: white;
  padding: 24px 0 0 0;
  position: relative;
  width: calc(100vw - 80px);
  overflow: hidden;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Logo {
    &.preAnimated {
      right: calc(100% - 209px);
      width: 209px;
    }
    transition: all 2s;
    position: absolute;
    right: -134px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 209px;
    overflow: hidden;

    .LogoImg {
      flex-shrink: 0;
      cursor: pointer;
    }

    .LogoText {
      transition: all 2s;
      margin: 0;
      padding: 0;
      margin-left: 24px;
      font-size: 18px;
      font-weight: normal;
      flex-shrink: 0;
    }
  }
  .TrajectoriesLinkBack{
    transition: all 2s;
    position: absolute;
    left: 0;
    box-shadow: 0 0 4px rgba(115, 40, 226, 0.2);
    &.preAnimated {
      left: -100%;
    }
    .BackArrow {
      //margin-right: 4px;
    }
    .BackText {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      white-space: nowrap;
      transition: all 1s;
      width: 0
    }
    &:hover {
      .BackText {
        color: #8533FF;
      }

      .BackArrow .color {
        stroke: #8533FF !important;
      }
    }
  }
}


@media screen and (max-width: 765px) {
  .sticky-header-0{
    transition: all 0.2s;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(115, 40, 226, 0);

  }
  .sticky-header-0.isSticky{
    padding-left: 30px;
    border-bottom: 1px solid rgba(115, 40, 226, 0.3);
  }

  .Header {
    width: inherit;
    padding: inherit;
    .Logo {
      transition: initial;
      top: inherit;
      transform: inherit;
      right: inherit;
      left: inherit;
      position: inherit;
      width: 100%;
      display: flex;
      justify-content: center;
      &.preAnimated {
        right: inherit;
        width: 100%;
      }
    }
    .TrajectoriesLinkBack {
      transition: all 0s;
      position: fixed;
      top: 23px;
      left: 16px;
      z-index: 1600;

      &.preAnimated {
        .BackArrow {
          display: none;
        }
      }
      .BackText {
        display: none;
      }
    }
  }
}


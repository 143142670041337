.spinner-container {
  position: absolute;
  top: 30%;
  left: 48%;
  animation: loader 1s ease-in-out infinite;
}
@keyframes loader {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}
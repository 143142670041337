.preset {
  transition: all .2s;
  padding: 16px;
  background: #F3F3F8;
  border-radius: 12px;
  scroll-snap-align: start;
  width: 390px;
  max-height: 100%;
  flex-grow: 3;
  min-width: min(calc(100vw - 30px), 450px);
  max-width: 50%;
  position: relative;
  animation: presetAppear 0.2s ease-out;

  &.iteractable:hover {
    box-shadow:
            0px 26px 11px rgba(100, 53, 165, 0.01),
            0px 15px 9px rgba(100, 53, 165, 0.03),
            0px 7px 7px rgba(100, 53, 165, 0.05),
            0px 0px 4px rgba(100, 53, 165, 0.06),
            0px 0px 0px rgba(100, 53, 165, 0.06);

    .actionButton {
      transform: scale(1.3);
    }
  }

  &.hidePreset{
    animation: presetDisappear 0.2s ease-in;
  }

  &.iteractable{
    .clickArea{
      cursor: pointer;
    }
  }

  .clickArea{
    position: absolute;
    cursor: default;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .actionButton {
    transition: all 0.2s;
    transform: scale(0);
    background: white;
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }

  .presetTopRow {
    display: flex;
    justify-content: space-between;
  }
  .presetIconFlex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
  .presetTitle {
    white-space: nowrap;
    margin-top: 16px;
    font-weight: 600;
    font-size: 14px;
    color: #1F1F22;
    margin-bottom: 12px;
  }

  .keywordsContainer {
    gap: 6px;
    display: flex;
    flex-wrap: wrap;

    .openKeywordsModalButton {
      color: #8533FF;
      padding: 8px 12px;
      background: #D8D7FE;
      border-radius: 8px;
      height: 31px;
      font-size: 12px;
      z-index: 12;
    }
    .keywordsContainer{
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 1000px) {
    .preset {
      margin-bottom: inherit !important;
      min-width: 100%;
      width: 100%;
      &.iteractable{
        .actionButton{
          transform: scale(1);
          padding: 0;
        }
      }
      .presetTopRow {
        justify-content: space-between;
      }
    }

}

@keyframes presetAppear {
  from {
    opacity: 0;
    transform: scale(0.9)
  }
  to {
    opacity: 1;
    transform: scale(1)
  }
}
@keyframes presetDisappear {
  from {
    opacity: 1;
    transform: scale(1)  }
  to {
    opacity: 0;
    transform: scale(0.9)
  }
}

.wand{
  .ray{
    transition: all 0.2s;
    opacity: 1;
    transform-origin: 50% 50%;
    transform-box: fill-box;
    box-shadow: 0 0 5px white;
  }
}

button:hover{
  .wand .ray{
    opacity: 1;
    box-shadow: 0 0 5px var(--color-5-dark);
  }
}
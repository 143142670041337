.disciplineHeaderContainer {
  min-height: 120px;
  padding: 32px 36px;
}

.keywords-wrapper {
  display: flex;
  flex-wrap: wrap;
}


.disciplineModalContainer {
  min-width: 520px;
  min-height: 388px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

}

.disciplineModalContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 22px 32px 36px;

}

.course-of-disc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin-bottom: 12px;
}

.classesNameDisc {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;

}

.classesNameDisc::first-letter {
  text-transform: capitalize;
}

.disciplinesElements {
  width: 100%;
  margin-bottom: 20px;
}

.course-of-disc::first-letter {
  text-transform: capitalize;
}

.disciplinesElement {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1F1F22;
}

.disciplinesElementsCount {
  color: #B7B6BC;
  padding-right: 25px;
  width: 20px;
}

.disciplinesElementsContainer {
  width: 100%;
  max-height: max(360px, 50vh);
  overflow-y: auto;
  padding-right: 22px;
}

.disciplinesElementsContainer::-webkit-scrollbar {
  width: 6px;
  background: rgba(243, 243, 248, 0.5);
}

.disciplinesElementsContainer::-webkit-scrollbar-thumb {
  background: #e7e8ee;
  border-radius: 6px;
}

.disciplines-elements__name {
  margin-left: 30px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.Prompt {
  color: black;
  top: 30px;
  z-index: 1000;
  left: -21px;
  width: 196px;
  text-align: left;
  min-height: 92px;
  position: absolute;
  filter: drop-shadow(-1px 0px 20px rgba(100, 53, 165, 0.06)) drop-shadow(1px 0px 20px rgba(100, 53, 165, 0.06)) drop-shadow(0px 26px 11px rgba(100, 53, 165, 0.01)) drop-shadow(0px 0px 4px rgba(100, 53, 165, 0.06)) drop-shadow(0px 0px 0px rgba(100, 53, 165, 0.06));
  background: #FFFFFF;
  border-radius: 12px;
  padding: 16px;
}

.Prompt::before {
  width: 21px;
  content: url("../../../images/icons/Static/VectorStrokeHoverArrow.svg");
  height: 14px;
  top: -14px;
  left: 20px;
  position: absolute;
}

.ContainerNameButton {
  padding: 30px;
  display: flex;
  gap: 12px;
  align-items: center;
}

hr {
  margin-top: 12px;
}

.VectorStroke {
  position: relative;
  border: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  transition: all .3s;
  outline: none !important;
}




.ListExamModal {
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1F1F22;

}



.ExamModalContainer {
  background: white;
  overflow-y: scroll;
  width: 100%;
  height: 532px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  .KeywordsModalContent{
    padding: initial;
  }
}

.KeywordsModalContentDisciplines {
  background: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.KeywordsModalHeader {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #1F1F22;
  margin: 0px;
}

.keywords-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: max(592px, 60vh);
  overflow-y: auto;
}

.keywords-container::-webkit-scrollbar {
  width: 6px;
  background: rgba(243, 243, 248, 0.5);
}

.keywords-container::-webkit-scrollbar-thumb {
  background: #e7e8ee;
  border-radius: 6px;
}

@media screen and (max-width: 1000px) {
  .disciplineModalContainer{
    min-width: inherit;
  }
  .ExamModalContainer {
    overflow-y: scroll;
    width: initial;
    height: initial;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 36px;
  }
  #ExamModalDiploma___BV_modal_content_ {
    background: white;
  }
}

.professionCard {
  transition: all 0.3s;
  padding: 24px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D2D2D4;
  border-radius: 16px;
  box-sizing: border-box;
  max-width: 540px;
  min-width: 320px;
  flex-shrink: 0;
  width: calc(100vw - 40px - 32px);
  position: relative;
  box-shadow: 0 0 0 rgb(181 181 197 / 25%);

  &:hover {
    box-shadow: 0px 26px 11px rgba(100, 53, 165, 0.01),
    0px 15px 9px rgba(100, 53, 165, 0.03),
    0px 7px 7px rgba(100, 53, 165, 0.05),
    0px 0px 4px rgba(100, 53, 165, 0.06),
    0px 0px 0px rgba(100, 53, 165, 0.06);
  }
  .cardData {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .professionCardTitle {
    color: black;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .professionCardKeyword {
    color: black;
    font-size: 12px;
    font-weight: 500;
    border-radius: 8px;
    padding: 8px 12px;
    margin-right: 8px;
    margin-bottom: 8px;
    height: 32px
  }
  .professionCardKeywords {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    max-height: 72px;
    min-height: 72px;
    overflow: hidden;
  }
  .professionCardCategory {
    color: #6E6D79;
    position: relative;
  }
}
@media screen and (max-width: 1000px) {
  .professionCard{
    //min-width: initial;
  }
}

@media screen and (max-width: 570px) {
  .finderLikeWrap{
    width: 49px;
    height: 74px;
  }
  .professionCard {
    margin: inherit;
    width: 100%;
    gap: 20px;

    img {
      min-width: 100px;
    }
    .professionCardTitle {
      text-align: left;
    }
    .professionCardKeywords {
      text-align: left;
      max-height: inherit;
      min-height: inherit;
    }
    .professionCardKeyword {
      height: inherit;
      font-size: 10px;
    }
  }
}

@keyframes rotateInfiniteClockwise {
  from {transform: rotate(0);}
  to {transform: rotate(360deg);}
}

@keyframes rotateInfiniteCounterClockwise {
  from {transform: rotate(0);}
  to {transform: rotate(-360deg);}
}

.svgAnime{
  transition: all 0.3s;
  transform-box: fill-box;
  transform-origin: center;
}

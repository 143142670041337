.ProfessionsPageContainer {
  //margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .ProfessionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 1920px;

    .CardHeaderWidth {
      width: calc(100%);
      z-index: 10;
      position: sticky;
      top: 0;
      padding-top: 12px;
      background: white;

      .ProfessionTitle {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 16px;
      }
    }
    .ProfessionContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: center;
      align-items: center;
      width: 100%;

      margin-bottom: 24px;

      .skeleton-v2 {
        opacity: .7;
        animation: skeleton-loading 1s linear infinite alternate;
        width: 540px;
        height: 200px;
        border-radius: 16px;
      }
      .ProfessionCardButton {
        background: none;
        border: none;
        outline: none;
        padding: 0 0;
        height: max-content;
        max-width: 540px;
        min-width: 420px;
        flex-shrink: 0;
      }
    }
    .ProfessionModalBottom {
      z-index: 2;
      max-height: 72px;
      //position: absolute;
      bottom: 32px;
      right: 32px;
      position: fixed;
      max-width: 504px;
      background: #F3F3F8;
      padding: 16px;
      transition-duration: 0.4s;
      border-radius: 12px;
      &.ProfessionModalBottomNon{
        right: -600px;
        overflow: hidden;
        transition-duration: 0.4s;
      }
      .CloseFeedback {
        border: inherit;
        background: inherit;
        position: absolute;
        right: 16px;
      }
      .Text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #1F1F22;
        .LinkText {
          color: #8533FF;
          background: inherit;
          padding: inherit;
          border: inherit;
        }

      }

    }
  }
}


@keyframes skeleton-loading {
  0% {
    background-color: rgba(235, 235, 235, 1);
  }
  100% {
    background-color: rgba(184, 184, 184, .7);
  }
}


@media screen and (max-width: 570px) {
  .ProfessionsPageContainer {
    .ProfessionsContainer {
      .CardHeaderWidth {
        //width: inherit;

      }
      .ProfessionContainer {
        margin-bottom: 24px;
        .skeleton-v2 {
          width: 325px;
          height: 365px;
        }
        .ProfessionCardButton {
          min-width: 340px;
          width: 100%;
          max-width: 100%;

        }
      }
      .ProfessionModalBottom {
        z-index: 2;
        width: calc(100% - 32px);
        max-height: 500px;
        right: 16px;
        //position: absolute;
        position: fixed;
        max-width: 420px;
        background: #F3F3F8;
        padding: 16px;
        transition-duration: 0.4s;
        border-radius: 12px;
        &.ProfessionModalBottomNon{
          right: -100%;
          overflow: hidden;
          transition-duration: 0.4s;
        }
        .Text {
          .LinkText {
            text-align: left;
          }

        }

      }
    }
  }

}

@media screen and (max-width: 410px) {
  .ProfessionsPageContainer {
    .ProfessionsContainer {
      .ProfessionContainer {
        .ProfessionCardButton {
          min-width: 320px;
          width: 100%;
          max-width: 100%;

        }
      }
    }
  }

  .ProfessionCardButton {
    min-width: 320px;
  }
}

.loadingModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  z-index: 2000;
}
.loadingModal.visible{
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadingModalContent{
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 30%;

}
.loadingModalHeader{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #1F1F22;
}
.loadingModalLoader{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.loader {
  animation: loader 1s ease-in-out infinite;
}

@keyframes loader {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}
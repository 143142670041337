.container-form{
  padding: 28px 36px 36px 40px;
  z-index: 1000;
  //min-width:448px;

  .form{
    padding-top: 20px;
    min-width: 448px;
    text-align: left;
    textarea, input{
      border: 1px solid #E7E8EE;
      border-radius: 8px;
      padding-left: 16px;
      width: 100%;
    }
    .containerText{
      text-align: left;
      margin-top: 22px;
      .prompt{
        color: red;
      }
    }
    .containerEmail{
      input{
        min-height: 40px;
      }
      margin-top: 16px;
    }
    .titleWrap {
      position: absolute;
      top: 18px;
      .title {

        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #1F1F22;
      }
    }
    .first-form{
      &.validation{
        border-color: red;
      }
      padding-top: 10px;
      resize: none;
      height: 120px;
    }
    .containerButton{
      margin-top: 24px;
      gap: 10px;
      display: flex;
      .submit{
        min-width: 142px;
        background: #8533FF;
        border-radius: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 12px;
        color: #FFFFFF;
      }
      .cancellation{
        min-width: 142px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 12px;
        border: 1px solid #8533FF;
        border-radius: 8px;
        color: #8533FF;

      }
    }
  }
  .RequestSent{
    min-width: 448px;

    .heartImg{
      margin-bottom: 40px;
    }
    .title{
      text-align: left;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #1F1F22;
      margin-bottom: 20px;
    }
    .gratitude{
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 24px;
      line-height: 20px;
      color: #1F1F22;
    }
    .containerButton{
      display: flex;
      .closeModal{
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 10px 10px 10px;
        color: #FFFFFF;
        background: #8533FF;
        border-radius: 8px;
        min-width: 142px;
      }

    }
  }
}
@media screen and (max-width: 1000px){
  .container-form{
    min-width: inherit;
    .form {
      min-width: 100%;
      .titleWrap{
        width: 70vw;
      }
      .containerButton{
        display: flex;
        flex-direction: column;
      }
    }
    .RequestSent{
      min-width: revert;
      .containerButton{
        .closeModal {
          width: 100%;
        }
      }
    }
  }
}


.TrajectoryPage {
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 24px;

  .semesterSeason{
    position: relative;
  }

  .titleNameDiscipline {
    display: flex;
    //position: sticky;
    //top: 64px;
    z-index: 1000;
    //border-bottom: 1px solid var(--gray-100);
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .StileText {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }

    .CoursesRow {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      z-index: 2;
      width: 100%;
      max-width: 470px;

      .CoursesRowFirstFlex {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        color: black !important;

        .CourseButtonActive {
          width: 50px;
        }

        .CourseButton {
          transition: all 0.2s;
          background: transparent;
          border: 0;
          font-weight: 400;
          font-size: 14px;
          width: 50px;
          height: 42px;
          display: flex;
          flex-direction: row;
          padding: 10px 0px;
          margin-right: 15px;
          margin-left: 15px;
          overflow: hidden;


          .Course {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            width: 50px;
            color: #1F1F22;
          }
        }
      }

      .CourseButtonDiploma {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        background: transparent;
        border: 0;
        padding: 10px 12px;
        width: 86px;
        height: 42px;
        flex-shrink: 0;
        color: var(--color-5-dark);
      }

    }
  }

  .HeaderDivider {
    transition: all 0.3s;
    padding: 0;
    margin: 0;
    height: 2px;
    border: 0;
  }

  .MainTrajectoryFlex {
    margin-bottom: 100px;
    margin-top: 24px;
    gap: 30px;
    display: flex;
    justify-content: space-between;
  }

  .MobileBlock {
    flex-grow: 2;

    .mobileBottomWrapper {
      display: none;
    }

    .TrajectorySmallHeader {
      color: #6e6d79;
      font-weight: 500;
      font-size: 12px;
      margin: 0;
      //width: 50%;
      margin-bottom: 16px;
    }
  }
}


@media screen and (max-width: 1000px) {
  .TrajectoryPage {
    //margin-bottom: 100px;

    .titleNameDiscipline {
      .StileText {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #1F1F22;
      }

      .CoursesRow {
        margin-top: 20px;
        background: inherit;

        .CoursesRowFirstFlex {
          .CourseButton {
            width: 14px;
            &.CourseButtonActive{
              width: 50px;
            }
            .Course {
              min-width: 50px;
            }
          }
        }

      }
    }

    .HeaderDivider {
      margin-bottom: 24px
    }

    .MobileBlock {
      flex: inherit;
      max-width: inherit !important;
      width: 100%;

      .mobileBottomWrapper {
        border-top: 1px solid #E7E8EE;
        padding: 16px 16px 34px 16px;
        transition-duration: 2s;
        max-height: 400px;
        z-index: 20;
        display: block;
        position: fixed;
        bottom: 0;
        background: white;
        width: 100%;
        left: 0;

        .BottomButtonsCurs {
          .buttonCourse {
            font-weight: 700;
            font-size: 14px;
            width: 100%;
            border: 1px solid #8533FF;
            border-radius: 8px;
            height: 40px;
            background: inherit;
            color: #8533FF;
          }
        }
      }

      .semesterSeason {
        display: none;
      }
    }
  }

  .Mobile {
    display: none !important;
  }

}

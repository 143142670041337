.borderBottom {
  z-index: 110;
  //transform: translateY(-1px);

  width: 100%;
}

.shadowBottom {
  z-index: 109;
  width: 100%;
  height: 1px;
  transform: translateY(-2px) translateX(-32px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.minTitle.bottom {
  transition: all 0.3s;
  //top: 123px;
  //position: sticky;
  background: white;
  z-index: 110;
  padding-top: 16px;
}

.minTitle.bottom.stickyPopup {
  //top: calc(122px + 320px);
}

.selectedSkillsBlock {
  transition: all 0.3s;
  //position: sticky;
  z-index: 111;
  //top: -420px;
  background: white;
  overflow: hidden;
  max-height: 380px;
  min-height: 320px;
  border-bottom: 1px solid rgba(231, 232, 238, 0);
}

.selectedSkillsBlock.stickyPopup {
  top: 122px;
}

.selectedSkillsBlock.forceHidden {
  max-height: 0;
  min-height: 0;
  top: -420px !important;
  border-bottom: 1px solid #E7E8EE;
}

.selectedSkillsBlock.forceHidden.stickyPopup {
  height: revert;
  border-bottom: revert;
}

.rightBlock .blockPreset {
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 20px;
  display: flex;
}

.blockPreset {
  display: flex;
  gap: 24px;
}

.blockMyPreset span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6E6D79;
}

.blockMyPreset {
  gap: 32px;
  width: 100%;
  background: #F3F3F8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 64px;
}

.leftContainer {
  position: relative;
}

.professionsContainer {
  max-width: inherit;
  margin-bottom: 48px;
}

.bottomRow .actively {
  background: #8533FF;
  color: #FFFFFF;
}

.flex-block {
  /*padding-top: 32px;*/
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/*.slide {*/
/*  !*max-width: 800px;*!*/
/*  position: inherit;*/
/*  max-height: 500px;*/
/*  overflow-y: auto;*/
/*  gap: 16px;*/
/*  display: flex;*/
/*  flex-wrap: wrap;*/
/*}*/

.bottomRow button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #6E6D79;
  background: #F3F3F8;
  border: inherit;
  padding: 8px 12px 8px 12px;
  border-radius: 20px;
}

.bottomRow {
  margin-bottom: 20px;
}

.minTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1F1F22;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 0;
  /*padding-top: 35px;*/
  padding-bottom: 16px;
}

.minTitle.top {
  align-items: center;
  //position: sticky;
  //top: 64px;
  background: white;
  z-index: 112;

  &.isSticky{
    border-bottom: 1px solid rgba(231, 232, 238, 1);
  }

  &.hideBorder{
    border-bottom: none !important;
  }
}

.buttonArrow {
  /*display: none;*/
  border: inherit;

  background: white;
  padding: 10px 12px;
  gap: 10px;
  border-radius: 8px;
}

.buttonArrow .mobil {
  display: none;
}

.buttonArrow .deck {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #1F1F22;
  transition: color .2s;
}

.buttonArrow .deck:hover {
  transition: color .2s;
  color: #6E6D79;
}

@media screen and (max-width: 1000px) {
  .minTitle {
    padding-top: 0;
    padding-bottom: 8px;
  }

  .shadowBottom {
    top: 140px;
    transform: translateY(2px) translateX(-32px);
  }

  .shadowBottom.first {
    top: 115px;
  }

  .minTitle.bottom {
    padding-top: 8px;
    top: 90px;
  }

  .minTitle.bottom.stickyPopup {
    top: 415px
  }
  .leftContainer .flexPreset {
    min-width: 300px;
  }

  .blockPreset {
    width: 100%;

  }

  .rightBlock .blockPreset {
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
  }

  .rightBlock .presets.noActiv {
    max-height: 0;
    min-width: 100%;
    transition-delay: 0.5s;
    transition-duration: 0.4s;

  }

  .rightBlock .presets.activ {
    max-height: 463px;
    min-width: 100%;
    transition-delay: 0.5s;
    transition-duration: 0.4s;
  }

  .presets.noActiv {
    min-width: 0;
  }

  .buttonArrow .mobil {
    display: inherit;
  }

  .deck {
    display: none;
  }

  .selectedSkillsBlock {
    margin-bottom: 32px;
    margin-right: 0;
  }

  .selectedSkillsBlock.stickyPopup {
    top: 116px;
  }

  .minTitle {
    display: flex;
    justify-content: space-between;
  }

  .clear {
    order: 2;
  }

  .flex-block {
    display: flex;
    flex-direction: column;
  }

  .rightBlock {
    margin-bottom: 200px;
    order: 2;
  }

  .presets {
    margin-bottom: inherit !important;
    min-width: 100%
  }

  .buttonArrow {
    background: inherit;
    border: none;
    display: inherit;
  }

  .arrowDown {
    transform: rotate(0);
    transition-duration: 0.3s;
  }

  .arrowUp {
    transform: rotate(-180deg);
    transition-duration: 0.3s;
  }
}

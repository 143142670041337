.ModalWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 36px 40px;
  gap: 24px;
  width: 100%;
  max-width: 550px;
  max-height: 530px;
  background: linear-gradient(180deg, rgba(133, 51, 255, 0.12) -0.41%, rgba(255, 255, 255, 0.3) 33.36%), #FFFFFF;
  border-radius: 16px;

  .Title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-top: 50px;
  }

  .Subtitle {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    max-width: 550px;
  }

  .DotsWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .Dot {
      width: 8px;
      height: 8px;
      background: #F3F3F8;
      border-radius: 50%;
    }

    .Dot.Active {
      transition: all .2s;
      background: #8533FF;
      animation: blob .3s linear;
    }
  }

  .ButtonsWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;

    .Button {
      width: 50%;
    }

    .SkipButton {
      order: 0;
    }

    .ContinueButton {
      order: 1;
    }
  }

  .OnboardingImage {
    width: 100%;
    height: 100%;
    max-width: 680px;
    max-height: 320px;
  }
}

@keyframes blob {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 1100px) {
  .ModalWrapper {
    max-width: none;
    max-height: none;

    .Title {
      font-size: 18px;
    }

    .Subtitle {
      font-size: 12px;
      max-width: 100%;
    }

    .ButtonsWrapper {
      flex-direction: column;

      .Button {
        width: 100%;
      }

      .SkipButton {
        order: 1;
      }

      .ContinueButton {
        order: 0;
      }
    }
  }
}
.CourseCardControlCard.notActive{
  background: #F3F3F8;
  color: #6E6D79;
  pointer-events: none;
}
.CourseCardControlCard {
  transition: all 0.2s;
  text-align: left;
  /*margin-bottom: 30px;*/
  background: white;
  color: #1F1F22;
  border: 1px solid #E7E8EE;
  font-weight: 500;
  font-size: 12px;
  padding: 12px 11px;
  border-radius: 12px;
  //max-width: 94px;
  min-width: 94px;

  .ControlTypeName{
    width: 62px;
  }
}
.CourseCardControlCard:hover{
  border: 1px solid #E7E8EE;
  box-shadow: 0px 26px 11px rgba(100, 53, 165, 0.01), 0px 15px 9px rgba(100, 53, 165, 0.03), 0px 7px 7px rgba(100, 53, 165, 0.05), 0px 0px 4px rgba(100, 53, 165, 0.06), 0px 0px 0px rgba(100, 53, 165, 0.06);
}
@media screen and (max-width: 1000px){
  .CourseCardControlCard b{
    font-size: 14px !important;
    line-height: 20px;
  }
  .CourseCardControlCard span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px; /* identical to box height, or 143% */
    color: #1F1F22;
  }
  .CourseCardControlCard{
    min-width: 100px;
    height: 66px;
    text-align: left;
  }
}

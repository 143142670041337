

.shareModal-cover {
  width: 100%;
}

.shareModalContainer {
  min-height: 388px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white;
}

.share-button {
  width: 220px;
}

.shareModalHeaderContainer {
  height: 158px;
  /*background: url("../../images/preview.svg") no-repeat center;*/
  -moz-background-size: 100%;
  -webkit-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
}

.shareContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 36px 36px;
  /*gap: 20px;*/
}

.shareContentHeader {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 22px;
  color: #1F1F22;
}

.shareForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 20px;
  width: 100%;
}

.modalShareInput {

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 10px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E7E8EE;
  border-radius: 8px;
}

.modalShareInput:focus {
  box-shadow: none;
  border: 1px solid rgba(210, 210, 212, 0.8);
}

.modalShareInput::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #B7B6BC;
}

.modalShareInputLabel {

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1F1F22;

}

.shareBtnContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
}

.secondaryButtonShare, .mainButtonShare {
  width: 100%;
  padding: 10px 18px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  outline: none !important;
  cursor: pointer;
  /*width: calc(100% - 8px);*/
}

.mainButtonShare:disabled {
  background: #F3F3F8;
  color: rgba(183, 182, 188, 0.7);
}

.shareInputContainer {
  text-align: left;
  width: 100%;
}

.shareInput {
  padding-left: 10px;
  width: 100%;
  height: 40px;
  color: #1F1F22;
  border: 1px solid #E7E8EE;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  transition: border 0.3s;
}

.shareInput:focus {
  transition: border 0.3s;
  border: 1px solid #8533FF;
  outline: none;
}

@media screen and (max-width: 1000px) {
  .shareModalHeaderContainer {
    /*background: url("../../images/shareBg.svg") no-repeat center;*/
  }
  .share-button {
    min-width: 100%;
  }
  .secondaryButtonShare, .mainButtonShare {
    width: 100%;
  }

  .shareBtnContainer {
    flex-direction: column;
  }

  .shareModalHeaderContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .shareContent {
    padding: 16px 20px 24px;
    gap: 16px;
  }

  .shareForm {
    gap: 16px;
  }
}

.Keyword {
  background: #EEEEFF;
  transition: all 0.2s, border 0.3s;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 12px;
  transform: scale(1);
  opacity: 1;
  line-height: 15px;
  border: 1px solid rgba(255, 255, 255, 0.01);
  &.deleted {
    transform: scale(0.9);
    opacity: 0;
  }

  &.selected{
    border: 1px solid var(--color-5-dark);
    cursor: pointer;
  }

  &.selectable{
    cursor: pointer;
    &:hover{
      background: #DDDDFD;
    }
  }
}

.deletedButton {
  //width: 8.5px;
  //height: 8.5px;
  margin-left: 8.81px;
}
